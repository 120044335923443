var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.datafetching)?_c('div',[_c('div',{staticClass:"fixed promotion-top fixed-content-area z-index-10",class:`${_vm.headerAnimClass}`},[_c('div',{staticClass:"fill-width white d-flex align-center",staticStyle:{"height":"44px"}},[_c('div',{staticClass:"d-flex justify-space-between fill-width align-center px-10"},[_c('v-icon',{staticClass:"mr-4",attrs:{"small":""},on:{"click":_vm.onClickPrevIcon}},[_vm._v(" $vuetify.icons.prevIcon ")]),_c('span',{staticClass:"oneLine rixgo-extrabold align-center gray900--text font-g16-24"},[_vm._v(_vm._s(_vm.singlePromotionDataGroup.title))]),_c('div',{staticClass:"pb-1",on:{"click":_vm.onClickShareIcon}},[_c('v-icon',[_vm._v("$vuetify.icons.shareIcon")])],1)],1)])]),_c('div',{class:`relative category-header-top  ${_vm.headerAnimClass}`},[_c('div',[(!_vm.usePromotionCheck)?_c('div',{staticClass:"fill-width pt-20 pb-20",staticStyle:{"background":"black"}},[_vm._m(0)]):_vm._e(),_vm._l((_vm.singlePromotionDataGroup.topInfo
          .beforeButtonList),function(item,index){return _c('div',{key:index},[_c('v-img',{attrs:{"eager":"","src":item.url,"height":"auto"}})],1)}),_c('div',{staticClass:"pt-15 pb-15 pl-35 pr-35",style:(`background-color: ${_vm.singlePromotionDataGroup.topInfo.brandButtonInfo.backgroundColor}`)},[_c('CellookButton',{attrs:{"buttonTitle":`브랜드샵 바로가기`,"background-color":_vm.singlePromotionDataGroup.topInfo.brandButtonInfo.buttonColor,"buttonTitleTextClass":`font-g14-20 rixgo-medium`,"textColorCode":_vm.singlePromotionDataGroup.topInfo.brandButtonInfo.textColor,"borderRadius":`${
            _vm.singlePromotionDataGroup.topInfo.brandButtonInfo.type === 'S'
              ? ''
              : '100px'
          }`},on:{"click":function($event){return _vm.moveToStore()}}})],1),_vm._l((_vm.singlePromotionDataGroup.topInfo
          .afterButtonList),function(item,index){return _c('div',{key:index},[_c('v-img',{attrs:{"eager":"","src":item.url,"height":"auto"}})],1)}),_c('div',[(_vm.usePromotionCheck)?_c('div',{class:`fill-width pt-15 pb-15 oneLine`},[_vm._m(1),_c('flip-countdown',{attrs:{"deadline":_vm.promotionEndDt,"showDays":_vm.showDays},on:{"timeElapsed":_vm.timeElapsedHandler}})],1):_vm._e()]),_c('div',[_c('v-img',{attrs:{"eager":"","src":_vm.singlePromotionDataGroup.promotionInfo.recommendGroup.group1
              .imageUrl,"height":"auto"}})],1)],2),_c('div',[_c('div',{staticClass:"fill-width pb-20"},[_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows":false,"height":"auto"}},[_c('VueSlickCarousel',_vm._b({ref:"carousel",attrs:{"arrows":false},on:{"afterChange":_vm.handleActive}},'VueSlickCarousel',_vm.carouselOption,false),_vm._l((_vm.singlePromotionDataGroup
                .promotionInfo.recommendGroup.group1.carouselItem
                .searchProductList),function(itemList,index){return _c('div',{key:itemList.productNo,staticClass:"pt-20"},[_c('ProductCardImage',{attrs:{"eventMallType":"single","rounded":"rounded-lg","imageIndex":index,"targetImage":_vm.targetImage,"product":itemList,"aspectRatio":228 / 308,"gtagName":`collabo_${_vm.singlePromotionNo}_rolling_${_vm.$numbering(
                  index + 1
                )}`},on:{"onClickBlurImage":_vm.onClickBlurImage}})],1)}),0),_c('div',{staticClass:"d-flex justify-center align-center pt-5"},[_c('v-icon',{attrs:{"x-small":""},on:{"click":function($event){return _vm.$refs.carousel.prev()}}},[_vm._v("$vuetify.icons.gray500PrevVector")]),_c('div',{staticClass:"font-g12-18 gray500--text campton-book text-center pt-1",staticStyle:{"width":"64px"}},[_vm._v(" "+_vm._s(_vm.$numbering(_vm.active + 1))+" / "+_vm._s(_vm.$numbering( _vm.singlePromotionDataGroup.promotionInfo.recommendGroup.group1 .carouselItem.searchProductList.length ))+" ")]),_c('v-icon',{attrs:{"x-small":""},on:{"click":function($event){return _vm.$refs.carousel.next()}}},[_vm._v("$vuetify.icons.gray500NextVector")])],1)],1)],1),_vm._l((_vm.singlePromotionDataGroup.promotionInfo
          .recommendGroup.group1.searchProductList),function(itemList,index){return _c('div',{key:itemList.productNo,staticClass:"pl-10 pr-10"},[_c('ProductCardX',{attrs:{"product":itemList,"aspectRatio":1,"gtagName":`collabo_${_vm.singlePromotionNo}_sec01_${_vm.$numbering(
            index + 1
          )}`}}),_c('div',{class:`${
            index ===
            _vm.singlePromotionDataGroup.promotionInfo.recommendGroup.group1
              .searchProductList.length -
              1
              ? 'mb-30'
              : 'bar mt-8 mb-8'
          }`})],1)}),_c('div',{staticClass:"pb-5"},[_c('div',[_c('v-img',{attrs:{"eager":"","src":_vm.singlePromotionDataGroup.promotionInfo.recommendGroup.group2
                .imageUrl,"height":"auto"}})],1)]),_vm._l((_vm.singlePromotionDataGroup.promotionInfo
          .recommendGroup.group2.searchProductList),function(itemList,index){return _c('div',{key:itemList.productNo,class:`${index === 0 ? 'pt-10' : ''} pl-10 pr-10`},[_c('ProductCardX',{attrs:{"imageReverse":true,"product":itemList,"aspectRatio":1,"gtagName":`collabo_${_vm.singlePromotionNo}_sec02_${_vm.$numbering(
            index + 1
          )}`}}),_c('div',{class:`${
            index ===
            _vm.singlePromotionDataGroup.promotionInfo.recommendGroup.group2
              .searchProductList.length -
              1
              ? 'mb-30'
              : 'bar mt-8 mb-8'
          }`})],1)}),_c('div',[_c('v-img',{attrs:{"eager":"","src":_vm.singlePromotionDataGroup.promotionInfo.bestGroup.imageUrl,"height":"auto"}})],1),_vm._l((_vm.bestCateogryList),function(item,index){return _c('div',{key:index,staticClass:"font-g16-25 rixgo-bold"},[_c('div',[_c('div',{staticClass:"pa-10"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"d-flex align-content-center flex-wrap pl-10 pr-10"},_vm._l((item.searchProductList),function(itemList,subListIndex){return _c('div',{key:itemList.productNo,staticClass:"width-50 pb-10"},[_c('ProductCardY',{staticClass:"ml-1 mr-1",attrs:{"useSoldOut":true,"product":itemList,"gtagName":`collabo_${_vm.singlePromotionNo}_cate${_vm.$numbering(
                  index + 1
                )}_${_vm.$numbering(subListIndex + 1)}`},on:{"isLikeChange":(yn) => _vm.isLikeChange(yn, index, subListIndex)}})],1)}),0),_c('div',{staticClass:"pt-5 pr-10 pl-10 pb-20"},[(
                (item.paging.page + 1) * item.paging.limit <
                _vm.singlePromotionDataGroup.promotionInfo.bestGroup.categoryList[
                  index
                ].searchProductList.length
              )?_c('div',{staticClass:"fill-width d-flex align-center justify-center",staticStyle:{"border":"1px solid blue","height":"47px","border-radius":"4px"},on:{"click":function($event){return _vm.viewMore(index)}}},[_c('div',{staticClass:"font-g13-18 rixgo-black blue--text"},[_vm._v(" 베스트 "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"font-g13-18 rixgo-regular blue--text pl-2"},[_vm._v(" 더보기 "),_c('v-icon',{staticClass:"pl-2 pb-1",attrs:{"size":"16"}},[_vm._v("$vuetify.icons.downIcon")])],1)]):_vm._e()]),_c('div',{staticClass:"border-gray"})])])})],2),_c('div',{staticClass:"font-g16-24 pa-10 rixgo-bold"},[_vm._v("다른 기획전")]),_c('div',{staticClass:"pl-10 pr-10"},_vm._l((_vm.singlePromotionDataGroup.bottomBannerList),function(item,index){return _c('div',{key:index},[_c('v-img',{staticClass:"mb-8 border-radius-8",attrs:{"aspect-ratio":335 / 95,"lazy-src":require('@/assets/images/img_back.png'),"src":item.imageUrl},on:{"click":function($event){return _vm.goExhibitionList(item.exhibitionNo, index)}}})],1)}),0),_c('div',{staticClass:"pt-20 pb-30 pl-10 pr-10"},[_c('CellookButton',{attrs:{"buttonTitle":`MD's Pick 보기`,"background-color":`#003399`,"buttonTitleTextClass":`font-g14-20 rixgo-bold`,"text-color":`white`},on:{"click":function($event){return _vm.goMainMDSPick()}}})],1)]),_c('Sharing',{attrs:{"content":_vm.singlePromotionDataGroup.shareInfo.description,"shareItem":_vm.singlePromotionDataGroup.shareInfo,"show":_vm.share,"url":_vm.singlePromotionDataGroup.shareInfo.copyUrl,"store":_vm.singlePromotionDataGroup.shareInfo.title,"sheetTitle":"기획전"},on:{"onClose":_vm.onCloseShare}})],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"white--text"},[_c('div',{staticClass:"d-flex justify-center font-g14-20 rixgo-bold pb-2"},[_vm._v(" 종료된 기획전입니다. ")]),_c('div',{staticClass:"d-flex justify-center font-g12-18 rixgo-regular"},[_vm._v(" 할인율과 혜택이 적용되지 않을 수 있습니다. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-center align-center pb-10"},[_c('div',{staticClass:"font-g16-24 campton-medium gray900--text"},[_vm._v(" COUNT DOWN ")])])
}]

export { render, staticRenderFns }