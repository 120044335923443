<!-- 카테고리 -->
<template>
  <div v-if="datafetching">
    <!-- 여기서부터 -->
    <div
      class="fixed promotion-top fixed-content-area z-index-10"
      :class="`${headerAnimClass}`"
    >
      <div class="fill-width white d-flex align-center" style="height: 44px">
        <div class="d-flex justify-space-between fill-width align-center px-10">
          <v-icon small class="mr-4" @click="onClickPrevIcon">
            $vuetify.icons.prevIcon
          </v-icon>
          <span
            class="oneLine rixgo-extrabold align-center gray900--text font-g16-24"
            >{{ singlePromotionDataGroup.title }}</span
          >
          <div class="pb-1" @click="onClickShareIcon">
            <v-icon>$vuetify.icons.shareIcon</v-icon>
          </div>
        </div>
      </div>
    </div>
    <!-- 여기까지 -->
    <div :class="`relative category-header-top  ${headerAnimClass}`">
      <div>
        <div
          v-if="!usePromotionCheck"
          class="fill-width pt-20 pb-20"
          style="background: black"
        >
          <div class="white--text">
            <div class="d-flex justify-center font-g14-20 rixgo-bold pb-2">
              종료된 기획전입니다.
            </div>
            <div class="d-flex justify-center font-g12-18 rixgo-regular">
              할인율과 혜택이 적용되지 않을 수 있습니다.
            </div>
          </div>
        </div>
        <div
          v-for="(item, index) in singlePromotionDataGroup.topInfo
            .beforeButtonList"
          :key="index"
        >
          <v-img eager :src="item.url" height="auto" />
        </div>
        <div
          class="pt-15 pb-15 pl-35 pr-35"
          :style="`background-color: ${singlePromotionDataGroup.topInfo.brandButtonInfo.backgroundColor}`"
        >
          <CellookButton
            :buttonTitle="`브랜드샵 바로가기`"
            :background-color="
              singlePromotionDataGroup.topInfo.brandButtonInfo.buttonColor
            "
            :buttonTitleTextClass="`font-g14-20 rixgo-medium`"
            :textColorCode="
              singlePromotionDataGroup.topInfo.brandButtonInfo.textColor
            "
            :borderRadius="`${
              singlePromotionDataGroup.topInfo.brandButtonInfo.type === 'S'
                ? ''
                : '100px'
            }`"
            @click="moveToStore()"
          />
        </div>
        <div
          v-for="(item, index) in singlePromotionDataGroup.topInfo
            .afterButtonList"
          :key="index"
        >
          <v-img eager :src="item.url" height="auto" />
        </div>
        <div>
          <div
            v-if="usePromotionCheck"
            :class="`fill-width pt-15 pb-15 oneLine`"
          >
            <div class="d-flex justify-center align-center pb-10">
              <div class="font-g16-24 campton-medium gray900--text">
                COUNT DOWN
              </div>
            </div>
            <flip-countdown
              :deadline="promotionEndDt"
              :showDays="showDays"
              @timeElapsed="timeElapsedHandler"
            ></flip-countdown>
          </div>
        </div>
        <div>
          <v-img
            eager
            :src="
              singlePromotionDataGroup.promotionInfo.recommendGroup.group1
                .imageUrl
            "
            height="auto"
          />
        </div>
      </div>
      <div>
        <div class="fill-width pb-20">
          <v-carousel hide-delimiters :show-arrows="false" height="auto">
            <VueSlickCarousel
              ref="carousel"
              v-bind="carouselOption"
              :arrows="false"
              @afterChange="handleActive"
            >
              <div
                v-for="(itemList, index) in singlePromotionDataGroup
                  .promotionInfo.recommendGroup.group1.carouselItem
                  .searchProductList"
                :key="itemList.productNo"
                class="pt-20"
              >
                <ProductCardImage
                  eventMallType="single"
                  rounded="rounded-lg"
                  :imageIndex="index"
                  :targetImage="targetImage"
                  :product="itemList"
                  :aspectRatio="228 / 308"
                  :gtagName="`collabo_${singlePromotionNo}_rolling_${$numbering(
                    index + 1
                  )}`"
                  @onClickBlurImage="onClickBlurImage"
                />
              </div>
            </VueSlickCarousel>
            <div class="d-flex justify-center align-center pt-5">
              <v-icon x-small @click="$refs.carousel.prev()"
                >$vuetify.icons.gray500PrevVector</v-icon
              >
              <div
                class="font-g12-18 gray500--text campton-book text-center pt-1"
                style="width: 64px"
              >
                {{ $numbering(active + 1) }} /
                {{
                  $numbering(
                    singlePromotionDataGroup.promotionInfo.recommendGroup.group1
                      .carouselItem.searchProductList.length
                  )
                }}
              </div>
              <v-icon x-small @click="$refs.carousel.next()"
                >$vuetify.icons.gray500NextVector</v-icon
              >
            </div>
          </v-carousel>
        </div>
        <div
          v-for="(itemList, index) in singlePromotionDataGroup.promotionInfo
            .recommendGroup.group1.searchProductList"
          :key="itemList.productNo"
          class="pl-10 pr-10"
        >
          <ProductCardX
            :product="itemList"
            :aspectRatio="1"
            :gtagName="`collabo_${singlePromotionNo}_sec01_${$numbering(
              index + 1
            )}`"
          />
          <div
            :class="`${
              index ===
              singlePromotionDataGroup.promotionInfo.recommendGroup.group1
                .searchProductList.length -
                1
                ? 'mb-30'
                : 'bar mt-8 mb-8'
            }`"
          />
        </div>
        <div class="pb-5">
          <div>
            <v-img
              eager
              :src="
                singlePromotionDataGroup.promotionInfo.recommendGroup.group2
                  .imageUrl
              "
              height="auto"
            />
          </div>
        </div>
        <div
          v-for="(itemList, index) in singlePromotionDataGroup.promotionInfo
            .recommendGroup.group2.searchProductList"
          :key="itemList.productNo"
          :class="`${index === 0 ? 'pt-10' : ''} pl-10 pr-10`"
        >
          <ProductCardX
            :imageReverse="true"
            :product="itemList"
            :aspectRatio="1"
            :gtagName="`collabo_${singlePromotionNo}_sec02_${$numbering(
              index + 1
            )}`"
          />
          <div
            :class="`${
              index ===
              singlePromotionDataGroup.promotionInfo.recommendGroup.group2
                .searchProductList.length -
                1
                ? 'mb-30'
                : 'bar mt-8 mb-8'
            }`"
          />
        </div>
        <div>
          <v-img
            eager
            :src="singlePromotionDataGroup.promotionInfo.bestGroup.imageUrl"
            height="auto"
          />
        </div>
        <div
          v-for="(item, index) in bestCateogryList"
          :key="index"
          class="font-g16-25 rixgo-bold"
        >
          <div>
            <div class="pa-10">
              {{ item.title }}
            </div>
            <div class="d-flex align-content-center flex-wrap pl-10 pr-10">
              <div
                v-for="(itemList, subListIndex) in item.searchProductList"
                :key="itemList.productNo"
                class="width-50 pb-10"
              >
                <ProductCardY
                  :useSoldOut="true"
                  :product="itemList"
                  class="ml-1 mr-1"
                  :gtagName="`collabo_${singlePromotionNo}_cate${$numbering(
                    index + 1
                  )}_${$numbering(subListIndex + 1)}`"
                  @isLikeChange="(yn) => isLikeChange(yn, index, subListIndex)"
                />
              </div>
            </div>
            <div class="pt-5 pr-10 pl-10 pb-20">
              <div
                v-if="
                  (item.paging.page + 1) * item.paging.limit <
                  singlePromotionDataGroup.promotionInfo.bestGroup.categoryList[
                    index
                  ].searchProductList.length
                "
                class="fill-width d-flex align-center justify-center"
                style="border: 1px solid blue; height: 47px; border-radius: 4px"
                @click="viewMore(index)"
              >
                <div class="font-g13-18 rixgo-black blue--text">
                  베스트 {{ item.title }}
                </div>
                <div class="font-g13-18 rixgo-regular blue--text pl-2">
                  더보기
                  <v-icon class="pl-2 pb-1" size="16"
                    >$vuetify.icons.downIcon</v-icon
                  >
                </div>
              </div>
            </div>
            <div class="border-gray"></div>
          </div>
        </div>
      </div>
      <div class="font-g16-24 pa-10 rixgo-bold">다른 기획전</div>
      <div class="pl-10 pr-10">
        <div
          v-for="(item, index) in singlePromotionDataGroup.bottomBannerList"
          :key="index"
        >
          <v-img
            :aspect-ratio="335 / 95"
            :lazy-src="require('@/assets/images/img_back.png')"
            :src="item.imageUrl"
            class="mb-8 border-radius-8"
            @click="goExhibitionList(item.exhibitionNo, index)"
          />
        </div>
      </div>
      <div class="pt-20 pb-30 pl-10 pr-10">
        <CellookButton
          :buttonTitle="`MD's Pick 보기`"
          :background-color="`#003399`"
          :buttonTitleTextClass="`font-g14-20 rixgo-bold`"
          :text-color="`white`"
          @click="goMainMDSPick()"
        />
      </div>
    </div>
    <Sharing
      :content="singlePromotionDataGroup.shareInfo.description"
      :shareItem="singlePromotionDataGroup.shareInfo"
      :show="share"
      :url="singlePromotionDataGroup.shareInfo.copyUrl"
      :store="singlePromotionDataGroup.shareInfo.title"
      sheetTitle="기획전"
      @onClose="onCloseShare"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Sharing from '../../common/dialogs/Sharing.vue'
import CellookButton from '@/components/common/buttons/CellookButton.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
import ProductCardImage from '../../common/widgets/ProductCardImage.vue'
import ProductCardX from '../../common/widgets/ProductCardX.vue'
import ProductCardY from '../../common/widgets/ProductCardY.vue'
import { GetSinglePromotionPlaneX } from '../../../api/displayAPI/DisplayAPI'
import FlipCountdown from 'vue2-flip-countdown'
export default Vue.extend({
  name: 'PromotionSingleMall',
  components: {
    CellookButton,
    ProductCardImage,
    ProductCardX,
    ProductCardY,
    VueSlickCarousel,
    Sharing,
    FlipCountdown,
  },
  watch: {
    async isLogined() {
      this.fetchLoading({ yn: true })
      await this.fetch()
      this.fetchLoading({ yn: false })
    },
    scrollTopNoti() {
      this.$tracking(`collabo_${this.singlePromotionNo}_gototop`)
    },
  },
  computed: {
    ...mapState('CommonStore', ['scrollTopNoti']),
    ...mapState('BannerStore', ['onBanner']),
    headerAnimClass() {
      return this.onBanner ? 'common-header-visible' : 'common-header-animate'
    },
  },
  props: {
    scrollLocation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      promotionEndDate: '',
      promotionEndTime: '',
      promotionEndDt: '',
      bestCateogryList: [],
      paging: {},
      usePromotionCheck: false,
      showDays: false,
      share: false,
      singlePromotionNo: '',
      shareItem: {},
      shareProps: {},
      singlePromotionDataGroup: {},
      GAData: {
        location: `${process.env.VUE_APP_CELLOOK_URL}/exhibitionEvent`,
        path: '/exhibitionEvent',
      },
      carouselOption: {
        centerMode: true,
        centerPadding: '72px',
        infinite: true,
        slidesToShow: 1,
        speed: 200,
        swipeToSlide: true,
        focusOnSelect: true,
      },
      targetImage: 0,
      active: 0,
      datafetching: false,
    }
  },
  async created() {
    this.fetchLoading({ yn: true })
    this.$getAppHtml.addEventListener('scroll', this.handleScroll)
    //shopId에 맞게 JSON 데이터 바인딩
    this.singlePromotionNo = this.$route.query.singlePromotionNo
    await this.fetch()
    this.fetchLoading({ yn: false })
  },
  destroyed() {
    this.$getAppHtml.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.setScrollLocation()
  },
  methods: {
    ...mapActions('HomeStore', ['fetchMainTab']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('ExhibitionStore', [
      'fetchEventExhibitionScrollLocation',
      'fetchPromotionSingleMallPaging',
    ]),
    ...mapActions('BannerStore', ['fetchOnBanner']),
    isEmpty(obj) {
      for (const key in obj) {
        return false
      }
      return true
    },
    setScrollLocation() {
      if (this.scrollLocation) {
        setTimeout(() => {
          this.$getAppHtml.scrollTo({
            top: this.scrollLocation,
            behavior: 'auto',
          })
        }, 1300)
      } else {
        this.fetchOnBanner({ onBanner: true })
      }
    },
    fetch() {
      GetSinglePromotionPlaneX({
        singlePromotionNo: this.singlePromotionNo,
      })
        .then((result) => {
          if (result) {
            this.singlePromotionDataGroup = result.data
            this.GAData.title = result.data.gaInfo.title
            this.singlePromotionDataGroup.shareInfo.copyUrl =
              `${process.env.VUE_APP_CELLOOK_URL}/promotionSingleMall?singlePromotionNo=` +
              this.singlePromotionNo
            this.singlePromotionDataGroup.shareInfo.url =
              `${process.env.VUE_APP_CELLOOK_URL}/promotionSingleMall?singlePromotionNo=` +
              this.singlePromotionNo
            const splitDate = result.data.endDt.split(' ')
            this.promotionEndDate = String(splitDate[0])
            this.promotionEndTime = String(splitDate[1])
            this.promotionEndDt =
              this.promotionEndDate.replaceAll('/', '-') +
              ' ' +
              this.promotionEndTime
            this.singlePromotionDataGroup.promotionInfo.bestGroup.categoryList.forEach(
              (value, index) => {
                this.paging[index] = {
                  page: 0,
                  limit: 20,
                }
                this.bestCateogryList[index] = _.cloneDeep(value)
              }
            )
            if (localStorage.getItem('promotionSingleMallPaging')) {
              this.paging = JSON.parse(
                localStorage.getItem('promotionSingleMallPaging')
              )
            }
            localStorage.removeItem('promotionSingleMallPaging')
            this.bestCateogryList.forEach((value, index) => {
              value.paging = this.paging[index]
              value.searchProductList = value.searchProductList.slice(
                0,
                (value.paging.page + 1) * value.paging.limit
              )
            })
            const today = this.getToday()
            const time = this.getTime()
            const fullDateToday = today + ' ' + time
            this.showDays = this.isCompareDate(fullDateToday, result.data.endDt)
            this.usePromotionCheck = this.isCompareFullDate(
              fullDateToday,
              result.data.endDt
            )
            this.$trackingPromotion(this.GAData)
            this.datafetching = true
          }
        })
        .catch((error) => {
          this.$router.push('/')
          console.log(error)
        })
        .finally(() => {
          this.datafetching = true
        })
    },
    onClickPrevIcon() {
      this.$tracking(`collabo_${this.singlePromotionNo}_back`)
      this.$router.push('/')
    },
    onClickShareIcon() {
      this.$tracking(`collabo_${this.singlePromotionNo}_share`)
      this.share = true
    },
    handleActive(active) {
      this.active = active
      this.targetImage = active
    },
    onClickBlurImage(imageIndex) {
      this.$tracking(
        `collabo_${this.singlePromotionNo}_rolling_${this.$numbering(
          imageIndex + 1
        )}`
      )
      this.$refs.carousel.goTo(imageIndex)
    },
    moveToStore() {
      this.$tracking(`collabo_${this.singlePromotionNo}_brandshop`)
      this.setExhibitionEventScrollLocation()
      this.$router.push({
        name: 'StoreHome',
        query: { shopId: this.singlePromotionDataGroup.shopId },
      })
    },
    goMainMDSPick() {
      this.$tracking(`collabo_${this.singlePromotionNo}_mdpick`)
      this.fetchMainTab({ tab: 3 })
      this.$router.push('/')
    },
    handleScroll() {
      this.fetchEventExhibitionScrollLocation(this.$getAppHtml.scrollTop)
      this.fetchPromotionSingleMallPaging(this.paging)
    },
    goExhibitionList(promotionNo, index) {
      this.$tracking(
        `collabo_${this.singlePromotionNo}_other_${this.$numbering(index + 1)}`
      )
      this.setExhibitionEventScrollLocation()
      this.$router.push({
        name: 'CategoryExhibitionList',
        query: {
          promotionNo: promotionNo,
          previousPage:
            'promotionSingleMall?singlePromotionNo=' + this.singlePromotionNo,
        },
      })
    },
    setExhibitionEventScrollLocation() {
      localStorage.setItem(
        'promotionSingleMallPaging',
        JSON.stringify(this.paging)
      )
      localStorage.setItem(
        'exhibitionEventScrollLocation',
        JSON.stringify(this.$getAppHtml.scrollTop)
      )
    },
    getToday() {
      const today = new Date()
      const year = today.getFullYear()
      const month = ('0' + (today.getMonth() + 1)).slice(-2)
      const day = ('0' + today.getDate()).slice(-2)
      return year + '/' + month + '/' + day
    },
    getTime() {
      const today = new Date()
      const hours = ('0' + today.getHours()).slice(-2)
      const minutes = ('0' + today.getMinutes()).slice(-2)
      const seconds = ('0' + today.getSeconds()).slice(-2)
      return hours + ':' + minutes + ':' + seconds
    },
    isCompareDate(today, promotionEndDate) {
      const todayDate = new Date(today)
      const endDate = new Date(promotionEndDate)
      const calcTime = endDate.getTime() - todayDate.getTime()
      return calcTime / (1000 * 60 * 60 * 24).toFixed(1) > 1
    },
    isCompareFullDate(fullDateToday, endDt) {
      const todayDate = new Date(fullDateToday)
      const endDate = new Date(endDt)
      return todayDate < endDate
    },
    timeElapsedHandler() {
      this.usePromotionCheck = false
    },
    viewMore(targetIndex) {
      sessionStorage.setItem('appInit', 'N')
      this.fetchLoading({ yn: true })
      this.paging[targetIndex] = {
        page: this.paging[targetIndex].page + 1,
        limit: this.paging[targetIndex].limit,
      }
      GetSinglePromotionPlaneX({
        singlePromotionNo: this.singlePromotionNo,
      })
        .then((result) => {
          if (result) {
            this.singlePromotionDataGroup = result.data
            this.singlePromotionDataGroup.promotionInfo.bestGroup.categoryList.forEach(
              (value, index) => {
                if (targetIndex === index) {
                  this.bestCateogryList[index] = _.cloneDeep(value)
                }
              }
            )
            this.bestCateogryList.forEach((value, index) => {
              if (targetIndex === index) {
                value.paging = this.paging[index]
                value.searchProductList = value.searchProductList.slice(
                  0,
                  (value.paging.page + 1) * value.paging.limit
                )
              }
            })
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.datafetching = true
          this.fetchLoading({ yn: false })
          this.fetchPromotionSingleMallPaging(this.paging)
        })
    },
    onCloseShare(type) {
      this.$tracking(`collabo_${this.singlePromotionNo}_share_${type}`)
      this.share = false
    },
    isLikeChange(yn, index, _index) {
      const trackingName = `collabo_${
        this.singlePromotionNo
      }_cate${this.$numbering(index + 1)}_${this.$numbering(_index + 1)}`

      if (yn) this.$tracking(`${trackingName}_like`)
      else this.$tracking(`${trackingName}_unlike`)
    },
  },
})
</script>
<style scoped>
.fixed-bar-page {
  margin-top: 6.1em;
}
.fixed-bar-exhibition-event-header {
  /* 2022.07.26 기준 이전 style */
  /* Flip Banner 추가로 인한 style 수정 */
  /* position: sticky;
  top: 0em;
  z-index: 20; */
  position: fixed;
  width: 100%;
  z-index: 20;
}
.bar {
  border-bottom: 1px solid #eaebed;
}
</style>
