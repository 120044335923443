
import Vue from 'vue'
import PromotionSingleMallView from '../../views/event/PromotionSingleMall.vue'
export default Vue.extend({
  name: 'ExhibitionEvent',
  components: { PromotionSingleMallView },
  data() {
    return {
      scrollLocation: 0,
    }
  },
  created() {
    if (localStorage.getItem('exhibitionEventScrollLocation')) {
      this.scrollLocation = Number(
        localStorage.getItem('exhibitionEventScrollLocation')
      )
    }
    localStorage.removeItem('exhibitionEventScrollLocation')
  },
})
